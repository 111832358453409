import isArray from 'lodash/isArray';
import reduce from 'lodash/reduce';
import { sanitizeEntity } from './sanitize';
import {
  PAY_ONLINE,
  PAY_ON_SITE,
  PAY_ONLINE_AND_PAY_ON_SITE,
  PAY_LINK_TO_ANOTHER_WEB_PAGE,
  PAYMENT_MODES_WITHOUT_STRIPE,
} from '../config/configListing';
import { FREE_PROCESS_NAME } from '../transactions/transaction';
import { getDisplayName } from './dataExtractors';
import { v4 } from 'uuid';
import moment from 'moment';
import { FORMAT, GAME } from './enums';
import { isArrayLength, safeFind } from './genericHelpers';
import { getGamerIdField } from '../config/games';
import { formatMoney } from './currency';
const { REACT_APP_TRANSACTION_FREE_BOOKING_PROCESS_NAME, REACT_APP_TRANSACTION_FREE_BOOKING_PROCESS_VERSION } = process.env;
export const filename = `TournaZone.csv`;
// NOTE: This file imports sanitize.js, which may lead to circular dependency

/**
 * Combine the given relationships objects
 *
 * See: http://jsonapi.org/format/#document-resource-object-relationships
 */
export const combinedRelationships = (oldRels, newRels) => {
  if (!oldRels && !newRels) {
    // Special case to avoid adding an empty relationships object when
    // none of the resource objects had any relationships.
    return null;
  }
  return { ...oldRels, ...newRels };
};

/**
 * Combine the given resource objects
 *
 * See: http://jsonapi.org/format/#document-resource-objects
 */
export const combinedResourceObjects = (oldRes, newRes) => {
  const { id, type } = oldRes;
  if (newRes.id.uuid !== id.uuid || newRes.type !== type) {
    throw new Error('Cannot merge resource objects with different ids or types');
  }
  const attributes = newRes.attributes || oldRes.attributes;
  const attributesOld = oldRes.attributes || {};
  const attributesNew = newRes.attributes || {};
  // Allow (potentially) sparse attributes to update only relevant fields
  const attrs = attributes ? { attributes: { ...attributesOld, ...attributesNew } } : null;
  const relationships = combinedRelationships(oldRes.relationships, newRes.relationships);
  const rels = relationships ? { relationships } : null;
  return { id, type, ...attrs, ...rels };
};

/**
 * Combine the resource objects form the given api response to the
 * existing entities.
 */
export const updatedEntities = (oldEntities, apiResponse, sanitizeConfig = {}) => {
  const { data, included = [] } = apiResponse;
  const objects = (Array.isArray(data) ? data : [data]).concat(included);

  const newEntities = objects.reduce((entities, curr) => {
    const { id, type } = curr;

    // Some entities (e.g. listing and user) might include extended data,
    // you should check if src/util/sanitize.js needs to be updated.
    const current = sanitizeEntity(curr, sanitizeConfig);

    entities[type] = entities[type] || {};
    const entity = entities[type][id.uuid];
    entities[type][id.uuid] = entity ? combinedResourceObjects({ ...entity }, current) : current;

    return entities;
  }, oldEntities);

  return newEntities;
};

/**
 * Denormalise the entities with the resources from the entities object
 *
 * This function calculates the dernormalised tree structure from the
 * normalised entities object with all the relationships joined in.
 *
 * @param {Object} entities entities object in the SDK Redux store
 * @param {Array<{ id, type }} resources array of objects
 * with id and type
 * @param {Boolean} throwIfNotFound wheather to skip a resource that
 * is not found (false), or to throw an Error (true)
 *
 * @return {Array} the given resource objects denormalised that were
 * found in the entities
 */
export const denormalisedEntities = (entities, resources, throwIfNotFound = true) => {
  const denormalised = resources.map(res => {
    const { id, type } = res;
    const entityFound = entities[type] && id && entities[type][id?.uuid];
    if (!entityFound) {
      if (throwIfNotFound) {
        throw new Error(`Entity with type "${type}" and id "${id ? id?.uuid : id}" not found`);
      }
      return null;
    }
    const entity = entities[type][id.uuid];
    const { relationships, ...entityData } = entity;

    if (relationships) {
      // Recursively join in all the relationship entities
      return reduce(
        relationships,
        (ent, relRef, relName) => {
          // A relationship reference can be either a single object or
          // an array of objects. We want to keep that form in the final
          // result.
          const hasMultipleRefs = Array.isArray(relRef.data);
          const multipleRefsEmpty = hasMultipleRefs && relRef.data.length === 0;
          if (!relRef.data || multipleRefsEmpty) {
            ent[relName] = hasMultipleRefs ? [] : null;
          } else {
            const refs = hasMultipleRefs ? relRef.data : [relRef.data];

            // If a relationship is not found, an Error should be thrown
            const rels = denormalisedEntities(entities, refs, true);

            ent[relName] = hasMultipleRefs ? rels : rels[0];
          }
          return ent;
        },
        entityData
      );
    }
    return entityData;
  });
  return denormalised.filter(e => !!e);
};

/**
 * Denormalise the data from the given SDK response
 *
 * @param {Object} sdkResponse response object from an SDK call
 *
 * @return {Array} entities in the response with relationships
 * denormalised from the included data
 */
export const denormalisedResponseEntities = sdkResponse => {
  const apiResponse = sdkResponse.data;
  const data = apiResponse.data;
  const resources = Array.isArray(data) ? data : [data];

  if (!data || resources.length === 0) {
    return [];
  }

  const entities = updatedEntities({}, apiResponse);
  return denormalisedEntities(entities, resources);
};

/**
 * Denormalize JSON object.
 * NOTE: Currently, this only handles denormalization of image references
 *
 * @param {JSON} data from Asset API (e.g. page asset)
 * @param {JSON} included array of asset references (currently only images supported)
 * @returns deep copy of data with images denormalized into it.
 */
const denormalizeJsonData = (data, included) => {
  let copy;

  // Handle strings, numbers, booleans, null
  if (data === null || typeof data !== 'object') {
    return data;
  }

  // At this point the data has typeof 'object' (aka Array or Object)
  // Array is the more specific case (of Object)
  if (data instanceof Array) {
    copy = data.map(datum => denormalizeJsonData(datum, included));
    return copy;
  }

  // Generic Objects
  if (data instanceof Object) {
    copy = {};
    Object.entries(data).forEach(([key, value]) => {
      // Handle denormalization of image reference
      const hasImageRefAsValue =
        typeof value == 'object' && value?._ref?.type === 'imageAsset' && value?._ref?.id;
      // If there is no image included,
      // the _ref might contain parameters for image resolver (Asset Delivery API resolves image URLs on the fly)
      const hasUnresolvedImageRef = typeof value == 'object' && value?._ref?.resolver === 'image';

      if (hasImageRefAsValue) {
        const foundRef = included.find(inc => inc.id === value._ref?.id);
        copy[key] = foundRef;
      } else if (hasUnresolvedImageRef) {
        // Don't add faulty image ref
        // Note: At the time of writing, assets can expose resolver configs,
        //       which we don't want to deal with.
      } else {
        copy[key] = denormalizeJsonData(value, included);
      }
    });
    return copy;
  }

  throw new Error("Unable to traverse data! It's not JSON.");
};

/**
 * Denormalize asset json from Asset API.
 * @param {JSON} assetJson in format: { data, included }
 * @returns deep copy of asset data with images denormalized into it.
 */
export const denormalizeAssetData = assetJson => {
  const { data, included } = assetJson || {};
  return denormalizeJsonData(data, included);
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} transaction entity object, which is to be ensured against null values
 */
export const ensureTransaction = (transaction, booking = null, listing = null, provider = null) => {
  const empty = {
    id: null,
    type: 'transaction',
    attributes: {},
    booking,
    listing,
    provider,
  };
  return { ...empty, ...transaction };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} booking entity object, which is to be ensured against null values
 */
export const ensureBooking = booking => {
  const empty = { id: null, type: 'booking', attributes: {} };
  return { ...empty, ...booking };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureListing = listing => {
  const empty = {
    id: null,
    type: 'listing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureOwnListing = listing => {
  const empty = {
    id: null,
    type: 'ownListing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} user entity object, which is to be ensured against null values
 */
export const ensureUser = user => {
  const empty = { id: null, type: 'user', attributes: { profile: {} } };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} current user entity object, which is to be ensured against null values
 */
export const ensureCurrentUser = user => {
  const empty = { id: null, type: 'currentUser', attributes: { profile: {} }, profileImage: {} };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} time slot entity object, which is to be ensured against null values
 */
export const ensureTimeSlot = timeSlot => {
  const empty = { id: null, type: 'timeSlot', attributes: {} };
  return { ...empty, ...timeSlot };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureDayAvailabilityPlan = availabilityPlan => {
  const empty = { type: 'availability-plan/day', entries: [] };
  return { ...empty, ...availabilityPlan };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureAvailabilityException = availabilityException => {
  const empty = { id: null, type: 'availabilityException', attributes: {} };
  return { ...empty, ...availabilityException };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensureStripeCustomer = stripeCustomer => {
  const empty = { id: null, type: 'stripeCustomer', attributes: {} };
  return { ...empty, ...stripeCustomer };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensurePaymentMethodCard = stripePaymentMethod => {
  const empty = {
    id: null,
    type: 'stripePaymentMethod',
    attributes: { type: 'stripe-payment-method/card', card: {} },
  };
  const cardPaymentMethod = { ...empty, ...stripePaymentMethod };

  if (cardPaymentMethod.attributes.type !== 'stripe-payment-method/card') {
    throw new Error(`'ensurePaymentMethodCard' got payment method with wrong type.
      'stripe-payment-method/card' was expected, received ${cardPaymentMethod.attributes.type}`);
  }

  return cardPaymentMethod;
};

/**
 * Get the display name of the given user as string. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned or deleted users, a translated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayNameAsString = user => {
  return getDisplayName(user) || '';
};

/**
 * DEPRECATED: Use userDisplayNameAsString function or UserDisplayName component instead
 *
 * @param {propTypes.user} user
 * @param {String} bannedUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayName = (user, bannedUserDisplayName) => {
  console.warn(
    `Function userDisplayName is deprecated!
User function userDisplayNameAsString or component UserDisplayName instead.`
  );

  return userDisplayNameAsString(user, bannedUserDisplayName);
};

/**
 * Get the abbreviated name of the given user. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned  or deleted users, a default abbreviated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserAbbreviatedName
 *
 * @return {String} abbreviated name that can be rendered in the UI
 * (e.g. in Avatar initials)
 */
export const userAbbreviatedName = (user, defaultUserAbbreviatedName) => {
  const hasAttributes = user && user.attributes;
  const hasProfile = hasAttributes && user.attributes.profile;
  const hasDisplayName = hasProfile && user.attributes.profile.abbreviatedName;

  if (hasDisplayName) {
    return user.attributes.profile.abbreviatedName;
  } else {
    return defaultUserAbbreviatedName || '';
  }
};

/**
 * A customizer function to be used with the
 * mergeWith function from lodash.
 *
 * Works like merge in every way exept that on case of
 * an array the old value is completely overridden with
 * the new value.
 *
 * @param {Object} objValue Value of current field, denoted by key
 * @param {Object} srcValue New value
 * @param {String} key Key of the field currently being merged
 * @param {Object} object Target object that is receiving values from source
 * @param {Object} source Source object that is merged into object param
 * @param {Object} stack Tracks merged values
 *
 * @return {Object} New value for objValue if the original is an array,
 * otherwise undefined is returned, which results in mergeWith using the
 * standard merging function
 */
export const overrideArrays = (objValue, srcValue, key, object, source, stack) => {
  if (isArray(objValue)) {
    return srcValue;
  }
};

/**
 * Humanizes a line item code. Strips the "line-item/" namespace
 * definition from the beginnign, replaces dashes with spaces and
 * capitalizes the first character.
 *
 * @param {string} code a line item code
 *
 * @return {string} returns the line item code humanized
 */
export const humanizeLineItemCode = code => {
  if (!/^line-item\/.+/.test(code)) {
    throw new Error(`Invalid line item code: ${code}`);
  }
  const lowercase = code.replace(/^line-item\//, '').replace(/-/g, ' ');

  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

export const USER_TYPE_PRIVATE_PERSON = 'private-person';
export const USER_TYPE_COMPANY = 'company';

export const isUserTypeCompany = userType => userType === USER_TYPE_COMPANY;
export const isUserCompany = user =>
  user?.attributes?.profile?.publicData?.userType === USER_TYPE_COMPANY;
export const isUserPrivatePerson = user =>
  user?.attributes?.profile?.publicData?.userType === USER_TYPE_PRIVATE_PERSON;

const listingPaymentMode = listing => listing?.attributes?.publicData?.paymentMode;
const seatsAvailable = listing => listing?.attributes?.publicData?.seats;
export const isListingPaymentModeOnline = listing => listingPaymentMode(listing) === PAY_ONLINE;
export const isSeatFull = (listing, registeredPlayers) => {
  return seatsAvailable(listing) <= registeredPlayers
};
const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
export const isListingPaymentModeOnSite = listing => Array.isArray(listingPaymentMode(listing)) ? listingPaymentMode(listing).findIndex((st) => PAYMENT_MODES_WITHOUT_STRIPE.includes(st)) >= 0 : listingPaymentMode(listing) === PAY_ON_SITE;

export const isListingPaymentModeOnlineAndPayOnSite = listing => {
  return Array.isArray(listingPaymentMode(listing)) ? listingPaymentMode(listing).findIndex((st) => PAYMENT_MODES_WITHOUT_STRIPE.includes(st)) >= 0 : listingPaymentMode(listing) === PAY_ONLINE_AND_PAY_ON_SITE
}

export const isListingPaymentModeLinkToAnotherWebPage = listing => listingPaymentMode(listing) === PAY_LINK_TO_ANOTHER_WEB_PAGE;

export const getListingDefaultProcessName = listing =>
  listing?.attributes?.publicData?.transactionProcessAlias?.split('/')[0];

export const getListingProcessName = listing => {
  let processName = null;
  if (isListingPaymentModeOnSite(listing)) {
    processName = FREE_PROCESS_NAME;
  } else {
    processName = getListingDefaultProcessName(listing);
  }

  return processName;
};


export const utcFormatDateFromUnix = (date) => {
  return moment(date * 1000).format("YYYY-MM-DD HH:mm");
}

export const onGetTransactionParam = (currentUser, listing, values, type = "", teammateName = "", teamMemberId = "", intl, ManualButton = "") => {


  const {
    bookingEnd,
    bookingStart,
    manualPaymentMode,
    isManual,
    payVia,
    lname,
    fname,
    TCGID,
    userName = "",
    isSeatAllFull = false
  } = values || "";
  const { id, attributes } = currentUser || {};

  const { email, profile } = attributes || {};
  const { firstName, lastName, displayName, publicData, protectedData } = profile || {};

  const { id: listingId, author, attributes: listingAttributes } = listing;
  const { price } = listingAttributes;
  const { amount, currency } = price;
  const { paymentMode, acceptAutomatically, twoHeadedGiantMagicTheGathering, buddyBattleOnePiece, tournamentdetails } = listingAttributes?.publicData || {};
  const mode = Array.isArray(paymentMode) ? paymentMode[0] : paymentMode;

  const game = listingAttributes.publicData.game || {};
  const gamerIdField = game ? getGamerIdField(game) : null;
  return {
    transactionId: v4(),
    listingId: listingId?.uuid,
    customerId: id?.uuid,
    providerId: author?.id?.uuid,
    processName: REACT_APP_TRANSACTION_FREE_BOOKING_PROCESS_NAME,
    processVersion: REACT_APP_TRANSACTION_FREE_BOOKING_PROCESS_VERSION,
    transitions: type === "inquiry" ?
      [
        {
          "transition": "transition/inquire",
          "createdAt": moment().toISOString(),
          "by": "customer"
        },
      ]
      : (acceptAutomatically && !isSeatAllFull)||ManualButton==true ?
        [
          {
            "transition": "transition/accept",
            "createdAt": moment().toISOString(),
            "by": "customer"
          },
        ]
        : [
          {
            "transition": "transition/request-payment",
            "createdAt": moment().toISOString(),
            "by": "customer"
          },
          {
            "transition": "transition/confirm-payment",
            "createdAt": moment().toISOString(),
            "by": "customer"
          },
        ],
    payoutTotal: amount,
    payinTotal: amount,
    currency: currency,
    notificationProvider: false,
    notificationCustomer: false,
    lineItems: [
      {
        "code": "line-item/hour",
        "unitPrice": amount,
        "lineTotal": amount,
        "reversal": false,
        "includeFor": [
          "customer",
          "provider"
        ],
        "quantity": "1"
      }
    ],
    lastTransitionedAt: moment().toISOString(),
    lastTransition: type === "inquiry"
        ? "transition/inquire": (acceptAutomatically && !isSeatAllFull)||ManualButton==true
        ? "transition/accept"
        : "transition/confirm-payment",
    protectedData: {
      acceptAutomatically: acceptAutomatically,
      twoHeadedGiantMagicTheGathering: twoHeadedGiantMagicTheGathering ? twoHeadedGiantMagicTheGathering : false,
      buddyBattleOnePiece: buddyBattleOnePiece ? buddyBattleOnePiece : false,
      payVia: payVia ? payVia : null,
      bookingEnd: bookingEnd,
      bookingStart: bookingStart,
      senderName: isManual?`${fname} ${lname}`:`${firstName} ${lastName}`,
      senderlastName: isManual?`${lname}`:`${lastName}`,
      senderfirstName: isManual?`${fname}`:`${firstName}`,
      senderdisplayName: `${displayName}`,
      userName: isManual?intl.formatMessage({ id: 'ManuelRegistrationForm.userNameDefault'}):userName ? userName : `${firstName} ${lastName}`,
      dateOfBirth: publicData?.dob ? publicData?.dob : null,
      senderEmailId: email,
      createdAt: moment().toISOString(),
      DigimonBandaID: protectedData?.DigimonBandaID ? protectedData?.DigimonBandaID : null,
      FleshandBloodGemID: protectedData?.FleshandBloodGemID ? protectedData?.FleshandBloodGemID : null,
      LorcanaID: protectedData?.LorcanaID ? protectedData?.LorcanaID : null,
      OnePieceBandaiID: protectedData?.OnePieceBandaiID ? protectedData?.OnePieceBandaiID : null,
      PokemonPlayerID: protectedData?.PokemonPlayerID ? protectedData?.PokemonPlayerID : null,
      YuGiOhKonamiID: protectedData?.YuGiOhKonamiID ? protectedData?.YuGiOhKonamiID : null,
      SWSpielerID: protectedData?.SWSpielerID ? protectedData?.SWSpielerID : null,
      DragonBallSuperCardGameID: protectedData?.DragonBallSuperCardGameID ? protectedData?.DragonBallSuperCardGameID : null,
      teammateName,
      teamMemberId,
      paymentMode: manualPaymentMode ? manualPaymentMode : mode,
      regStatus: isManual?"Bestätigt":"Offen",
      decklist: "Offen",
      paymentStatus: "Offen",
      appeared: "Offen",
      TCGID: TCGID ? TCGID : null,
      isCustomerRead:false,
      isProviderRead:false,
      tournamentdetails
    },
    customerName: `${firstName} ${lastName}`,
    providerName: author.attributes.profile.displayName,
    title: listingAttributes.title,
    Price: formatMoney(intl, price),
    gamerId: gamerIdField ? gamerIdField : null,
    paymentOption: payVia ? payVia : null,
    anmeldeStatus: isManual?"Bestätigt":"Offen",
    bezahlStatus: "Offen",
    decklisteEingereicht: "offen",
    erschienen: "offen",
    startDate: utcFormatDateFromUnix(bookingStart),
    startDateUnix: bookingStart,
    endDate: utcFormatDateFromUnix(bookingEnd),
    endDateUnix: bookingEnd
  }
}

export const onGetMessageParams = (strapiTransactionParam, message, teamMemberName = "", teamMemberId = "") => {
  const { transactionId, customerId, providerId, protectedData, customerName, providerName, title } = strapiTransactionParam;
  const { senderName, recieverName } = protectedData;
  return {
    transactionId,
    chatId: transactionId,
    senderId: customerId,
    receiverId: providerId,
    message,
    chatType: "TRANSACTION",
    read: false,
    isDeleted: false,
    chatDetails: {
      createdAt: new Date(),
      senderName,
      recieverName,
      teamMemberName,
      teamMemberId,
    },
    senderName: customerName,
    receiverName: providerName,
    tournamentName: title,
    providerName: providerName
  }
};

export const transformToEvents = (transactions, listingFields) => {
  const formatOptions = safeFind(listingFields, f => f?.key.includes(FORMAT))?.enumOptions;
  const gameOptions = isArrayLength(listingFields)
    ? safeFind(listingFields, f => f?.key === GAME)?.enumOptions
    : null;

  return transactions?.flatMap(transaction => {
    const { attributes } = transaction;
    const { title, publicData } = attributes;

    const eventFormat = formatOptions.find(format => format.option === publicData.format);
    const eventGame = gameOptions.find(game => game.option === publicData.game);

    return publicData?.eventDates?.map((dateString, index) => {
      const dateStart = moment(dateString);

      // Skip past dates
      if (dateStart.isBefore(moment())) {
        return null;
      }

      const start = dateStart.toDate();
      const end = moment(dateStart).add(60, 'minutes').toDate(); // Add 60 minutes to start

      return {
        index,
        id: transaction.id.uuid,
        eventTitle: `${eventGame?.label} | ${eventFormat?.label} | ${title}`,
        title,
        game: publicData?.game,
        format: publicData?.format,
        start,
        end,
        allDay: false,
        currentListing: transaction,
      };
    }).filter(event => event !== null) || []; // Filter out null values

  }) || []; // Return an empty array if transactions is not defined
};


export const getDateTimeFormat = (timestamp) => {
  return moment(+(timestamp)).format('YYYY-MM-DD HH:mm');
};

export const getDateFormat = (timestamp) => {
  return moment(+(timestamp) * 1000).format('YYYY-MM-DD HH:mm');
};

export const copyTextToClipboard = (text) => {
  typeof window != "undefined" && navigator.clipboard.writeText(text)
    .then(() => {
      console.log('Text copied to clipboard');
    })
    .catch(err => {
      console.error('Error copying text: ', err);
    });
};
